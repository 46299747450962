<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    
    <default-author-card />

    <role-card
      v-for="(role, i) in sortedRolesWithActiveClaimModules"
      :id="`role-${parseIdFromString(role.name)}`"
      :key="role.id"
      :claims="role.claims"
      class="mt-4"
      :is-admin="role.isAdmin"
      :is-default="role.isDefault"
      :name="role.name"
      :role-id="role.id"
      :roles="roles"
      @remove="handleRemoveSuccess(i, role.name)"
    />

    <form
      id="add-role"
      class="card mt-4"
      @submit.prevent="submitAddForm"
    >
      <div class="card-body">
        <div class="d-flex align-items-center mb-sm-0 mb-4">
          <div class="w-50">
            <h5>
              Maak een nieuwe Rol aan
            </h5>

            <material-input
              id="add-role-name"
              v-model="newRoleName"
              is-required
              label="Vul hieronder de naam van de nieuwe rol in."
              placeholder="Naam van de rol"
              variant="static"
            />
          </div>
          
          <div class="w-50 text-end">
            <material-button
              class="ms-2"
              variant="gradient"
            >
              Rol aanmaken
            </material-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { add, getAll } from '@/api/providers/roles';
import { parseIdFromString } from '@/helpers/parsers';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import RoleCard from '@/components/Roles/Card.vue';
import SideNav from '@/components/UI/SideNav.vue';
import DefaultAuthorCard from '../../components/Roles/DefaultAuthor.vue';

export default {
  name: 'DashboardRoles',

  components: {
    MaterialButton,
    MaterialInput,
    RoleCard,
    SideNav,
    DefaultAuthorCard
},

  data: () => ({
    newRoleName: '',
    roles: [],
  }),

  computed: {
    ...mapGetters('organization', [
      'sortedModuleIds',
      'sortedModules',
    ]),

    newRoleClaims() {
      return this.sortedModules.map(({ id, name }) => ({
        moduleId: id,
        moduleName: name,
        create: false,
        update: false,
        delete: false,
        react: false,
      }));
    },

    sortedRolesWithActiveClaimModules() {
      return this.roles
        .map(({ claims, ...role }) => ({
          ...role,
          claims: claims
            .filter(({ moduleId }) => this.sortedModuleIds.includes(moduleId)),
        }));
    },

    navigationItems() {
      return [
        ...this.sortedRolesWithActiveClaimModules.map(({ name }) => ({
          href: `#role-${this.parseIdFromString(name)}`,
          icon: 'lock',
          name: name,
        })),
        {
          href: `#add-role`,
          icon: 'add_circle_outline',
          name: 'Rol toevoegen',
        },
      ];
    },
  },

  mounted() {
    this.loadRoles();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    handleRemoveSuccess(index, name) {
      this.roles.splice(index, 1);
        this.addNotification({
          description: `${name} is verwijderd.`,
          icon: {
            component: 'lock_person',
          },
        });
    },

    loadRoles() {
      getAll(true)
        .then((data) => {
          this.roles = data || [];
        });
    },

    parseIdFromString,

    submitAddForm() {
      add(this.newRoleName, this.newRoleClaims)
        .then((data) => {
          this.roles.push(data);
          this.newRoleName = '';
        })
        .catch((error) => error.default());
    },
  },
};
</script>